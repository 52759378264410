import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";

import { Link, useNavigate, useParams } from 'react-router-dom';



import SignaturePad from 'signature_pad';
import { Html5QrcodeScanner } from 'html5-qrcode';



export default function Login(){
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Add a new state variable for loading status

   
    let navigate =useNavigate();
    const [user, setUser]=useState({
      email_agent: '',
      pass_agent:''
  
    })
    const handleChange = (e) => {
  
      setUser(({...user, [e.target.name]: e.target.value}));
  
  }
  
  
  
  const submitForm=(e) => {
  e.preventDefault();
  const sendData = {
      email_agent:user.email_agent,
      pass_agent:user.pass_agent
  
  }
  
      setIsLoading(true);
  console.log(sendData);
  axios.post('https://jobs-conseil.tech/laposte/qrapp/login1/',sendData).then((result)=>{
   if (result.data.Status === '200'){
  window.localStorage.setItem('email_admin', result.data.email_agent);
  
  window.localStorage.setItem('id_admin', result.data.id_agent);
 
  console.log(result.data);
 navigate('/Cnt');
     
     
   } else {
      setSuccessMessage("Echec d'authentification! ");
  
   }
  }
  
  )
  
  }
  
    return(
    <Fragment>
        <div className="main-wrapper">
<div className="page-content change-password-col register-col">
<div className="list no-hairlines custom-form">

<div className="logo">
<a href="#"><img style={{height:'100px'}} src="https://jobs-conseil.tech/laposte/1590577918787-removebg-preview.png" alt="LOGO la poste" /></a>
</div>
<div className="register-inner-col">
<div className="top-title">
<div>
<h3>Connexion</h3>
</div>
<div>
<a href="#">Avez-vous accès?</a>
</div>
</div>

 <div id="notif" className="text-center"></div>



									{successMessage && (
                <div className="alert alert-danger" role="alert">
                  {successMessage}
                </div>

                
              )} 

<form onSubmit={submitForm}>
<ul className="change-list">
<li className="item-content item-input">
<div className="item-col">
<div className="item-input-wrap">
<input type="text" name='email_agent' value={user.email_agent} onChange={handleChange} autocomplete="off" placeholder="Email" />
<div className="item-input-icon"><img src="./assets/assets/img/email.svg" alt="" /></div>
</div>
</div>
</li>
<li className="item-content item-input">
<div className="item-col">
<div className="item-input-wrap">
<input name='pass_agent' value={user.pass_agent} onChange={handleChange} autocomplete="off" type="password" placeholder="Mot de passe" />
<div className="item-input-icon"><img src="./assets/assets/img/lock-icon.svg" alt="" /></div>
</div>
</div>
</li>
<li className="col-50 save-password">
</li>
<li className="col-50 forgot-password">
<a href="#"></a>
</li>
<li className="item-content item-input">
<div className="item-input-wrap submit-btn-col">
<button type="submit" name="login-btn" className="btn btn-submit">CONNEXION</button>
</div>
</li>

</ul>
</form>



</div>
</div>
</div>
</div>
    </Fragment>

    )
}
