import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";

import { Link, useNavigate, useParams } from 'react-router-dom';


import { Html5QrcodeScanner } from 'html5-qrcode';



  function downloadFile(fileUrl, fileName, onProgress) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';
  
    xhr.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const percentComplete = ((event.loaded / event.total) * 100).toString().slice(0, 4);
       
        onProgress(percentComplete);
      }
    });
  
    xhr.addEventListener('load', event => {
      if (xhr.status === 200) {
        onProgress(100);
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Erreur lors du téléchargement du fichier:', xhr.status);
      }
    });
  
    xhr.send();
  }

  export default function Sacs() {
  const progressBarStyles = {
    width: '90%',
    height: '10px',
    marginLeft:'5px',
    marginRight:'5px',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
    overflow: 'hidden',
  };

    const { id_state } = useParams();
const { titre } = useParams();
    const [scanResult, setScanResult] = useState(null);
    const [email_agent, setAuth] = useState('');
const [agences, setAgences] = useState([]);
const [filter, setFilter] = useState("");
const navigate = useNavigate();
const [successMessage, setSuccessMessage] = useState("");
  const REDIRECT_DELAY = 2000; 
  const [agences30, setAgences30] = useState([]);
  
  const userRole = localStorage.getItem('role');
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
	  setMenuOpen(!menuOpen);
	  setMenuActive(!menuOpen);
	};
	const closeMenu = () => {
	  setMenuOpen(!menuOpen);
	  setMenuActive(!menuOpen);	// Code pour fermer le menu
	};
  
  const closeQr = () => {
	 document.getElementById('oki').click();
	};

  const [isLoading, setIsLoading] = useState(false); // Add a new state variable for loading status

  const [colis, setColis] = useState([]);


  const email = localStorage.getItem('id_admin');
  if (email === null) {
    navigate('/');
  }

useEffect(() => {
  const fetchData = async () => {
    try {
      await getColis();
    
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  fetchData(); // Exécute fetchData une fois immédiatement

  const intervalId = setInterval(fetchData, 1000); // Exécute fetchData toutes les 3 secondes

  return () => {
    clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
  };
}, []);



const getColis = async () => {
  try {
    const response = await axios.get(`https://jobs-conseil.tech/laposte/qrapp/sacs2/`);
    setColis(response.data);
    
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error);
  }
};

  const [data1, setData1] = useState(null);
  useEffect(() => {
    const success = async (result) => {
      setScanResult(result);
     
      const sendScanResult = async (result) => {
        setIsLoading(true);
        const url = `https://jobs-conseil.tech/laposte/qrapp/sacs1/`;
        const data = { scanResult: result };
  
        try {
          const response = await axios.post(url, data);
          console.log(response.data);
          setData1(response.data);
          setIsLoading(false);
          document.getElementById('sign').style.display='block';
         
        } catch (error) {
          console.error(error);
        }
      };
  
      await sendScanResult(result);
  
      setSuccessMessage("Code QR scanné avec succès !");
  
      setTimeout(() => {
        document.getElementById('noti').innerHTML = '';
        setScanResult(null);
      }, REDIRECT_DELAY);
    };
  
    const error = (err) => {
      console.warn(err);
    };
  
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });
  
    scanner.render(success, error);
   

    
  }, []);
  







 


useEffect(() => {

 
  
 




            
  const loadScripts = () => {
      // Remove existing script elements added by the component
      const scriptElements = Array.from(document.getElementsByTagName('script'));
      const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));

      componentScriptElements.forEach((script) => {
        document.body.removeChild(script);
      });


  
    const script1 = document.createElement('script');
    script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/signature_pad/1.5.3/signature_pad.min.js';
    script1.async = true;



    const script3 = document.createElement('script');
    script3.src = './assets/assets/js/jquery-3.6.0.min.js';
    script3.async = true;

   
    const script5 = document.createElement('script');
    script5.src = './assets/assets/js/bootstrap.bundle.min.js';
    script5.async = true;


    const script6 = document.createElement('script');
    script6.src = './assets/assets/plugins/swiper/js/swiper.min.js';
    script6.async = true;

    const script8 = document.createElement('script');
    script8.src = './assets/assets/js/script.js';
    script8.async = true;


    document.body.appendChild(script1);

    document.body.appendChild(script3);

    document.body.appendChild(script5);
   
    document.body.appendChild(script6);
   
    document.body.appendChild(script8);
   
  };
    loadScripts();


    return () => {
      const scriptElements = Array.from(document.getElementsByTagName('script'));
      const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));

      componentScriptElements.forEach((script) => {
        document.body.removeChild(script);
      });
    };



    
  

}, []);
const [data2, setData2] = useState(null);

const [destination, setDestination] = useState('');
const [selectedColis, setSelectedColis] = useState([]);
const handleSubmit = (event) => {
  event.preventDefault();

  // Récupérer les données du formulaire
  const form = event.target;
  const data = new FormData(form);

  // Ajouter les valeurs sélectionnées au tableau "selectedColis"
  selectedColis.forEach((colisId) => {
    data.append('selectedColis[]', colisId);
  });

  // Ajouter la destination
  data.append('destination', destination);

  // Effectuer la requête POST vers le serveur PHP
  fetch('https://jobs-conseil.tech/laposte/qrapp/generer/', {
    method: 'POST',
    body: data,
  })
    .then((response) => response.json())
    .then((responseData) => {
      // Traiter la réponse du serveur
      console.log(responseData);
      setData2(responseData);
    })
    .catch((error) => {
      console.error('Erreur lors de la requête:', error);
    });
};


// Gérer la sélection/désélection d'un colis
const handleSelectColis = (colisId) => {
  if (selectedColis.includes(colisId)) {
    setSelectedColis(selectedColis.filter((id) => id !== colisId));
  } else {
    setSelectedColis([...selectedColis, colisId]);
  }
};


const handlePrint = (qrcode_etu, matricule) => {
	const qrCodeWindow = window.open('', '_blank');
	const qrCodeImage = document.createElement('img');
	
	qrCodeImage.src = qrcode_etu;
	qrCodeImage.style.height = '250px';
	qrCodeWindow.document.body.appendChild(qrCodeImage);
	
	// Ajouter le nom, le prénom et le matricule au document d'impression
	const infoDiv = document.createElement('div');
	infoDiv.innerHTML = `<br/>Matricule: ${matricule}`;
	qrCodeWindow.document.body.appendChild(infoDiv);
	
	qrCodeWindow.print();
	};



  const [progress, setProgress] = useState(0);

  const handleDownload = () => {
    const fileUrl = `https://jobs-conseil.tech/laposte/qrapp/upload/${data2.Codeqr}`;
    const fileName = `${data2.Codeqr}`;
    downloadFile(fileUrl, fileName, setProgress);
  };
  const logOut = () => {
    localStorage.removeItem('id_admin');
    localStorage.clear();
    navigate('/');
  };
    return(
    <Fragment>
		
								
									

        <div className="main-wrapper">

<div className="home">
<div className="navbar two-action no-hairline">
<div className="navbar-inner d-flex align-items-center">

<div style={{textAlign:'center'}} className="sliding custom-title">Génération des sacs</div>

</div>
</div>
</div>





<div className="page-content">
    
    
    

  







<div className="row gy-5 g-xl-10">



















<div className="col-xl-12 mb-5 mb-xl-10">
									
                                    <div className="card card-flush h-xl-100">
<div className="card-header pt-7">
											
<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bold text-gray-800">Scanner Code QR {titre}</span>
													<span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
												</h3>						
											
											
												
											
											</div>


                                            <div className="card-body bg-light">
											
										
												
                                            <div className="tab-content">

<div  id='example1_wrapper120r'>
<div id='noti'>

</div>


        <div id="reader"></div>









               </div>
               {isLoading ? (
    <div align='center' style={{textAlign:'center'}}>  <span style={{fontSize:'30px'}} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span></div>
    ) : (
       <></>  
      )}


       
<div align="center">
   
<form onSubmit={handleSubmit}>
<div style={{overflowX:'auto',overflowY:'auto',maxHeight:'350px'}}>
{colis.length!==0 &&(
  
                          <table style={{width:'100%',marginTop:'10px'}} className='colored primary'>
                <thead border='1px'>
                  <tr>
                  <th></th>
                      <th>Matri.</th>
                      <th>Nature colis</th>
                      <th>Catégorie</th>
                      <th>Desti.</th>
                     
                     
                
                      
                  </tr>
                </thead>
        
                <tbody>

        {colis.map((coli, key) =>
                                   <tr key={key}>
                                    <td> <input
              type='checkbox'
              value={coli.id_colis}
              checked={selectedColis.includes(coli.id_colis)}
              onChange={() => handleSelectColis(coli.id_colis)}
              name={`val${coli.id_colis}`}
              id={`val${coli.id_colis}`}
            /></td>

                                   <td style={{fontSize:'12px',color:'black'}}>P-00{coli.id_colis}</td>
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.nom}</td>
                                   
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.categorie}</td>
                                
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.destination}</td>
                                  
                                 
                                 </tr>
                   )}
                </tbody>
              </table>


              
)}


              </div>

              {colis.length!==0 &&(
<>
             
               <div id='sign' style={{display:'block',marginTop:'20px'}}>

               <input autoComplete='off' className='form-control' placeholder='Renseignez la destination finale'
  type="text"
  name="destination"
  value={destination}
  onChange={(event) => setDestination(event.target.value)}
/>

         
      </div>

      <button style={{marginTop:'10px'}} type='submit'><i className='fa fa-qrcode'></i> Générer le sacs</button>
      </>
)}

{data2 &&(

<>

  <img src={`https://jobs-conseil.tech/laposte/qrapp/upload/${data2.Codeqr}`} style={{ height: '150px',marginTop:'10PX' }} /><br></br>
  {progress && progress!==100 ? (
    <> Téléchargement en cours...{progress}%
       
                            <div style={progressBarStyles} className="progress-bar">
                                
                                
        <div className="progress" style={{ width: `${progress}%`, height: '100%',
          backgroundColor: '#4CAF50',
        
          transition: 'width 0.3s ease-in-out', }}></div>
      </div>
      </>

) : ( 
    <span></span> 
)}
  <a
        
        className="btn"  onClick={handleDownload}
        style={{ borderColor: 'black', marginTop: '10px' }}
      >
        <i className="fa fa-download"></i> Télécharger
      </a>
      <a
        onClick={() => handlePrint(`https://jobs-conseil.tech/laposte/qrapp/upload/${data2.Codeqr}`,data2.Codeqr)}
        className="btn"
        style={{ borderColor: 'black', marginLeft: '3px', marginTop: '10px' }}
      >
        <i className="fa fa-print"></i> Imprimer
      </a>

</>

)}
              {data1 && data1[0].Message ==='ok' ?(
<>
             
               <div id='sign' style={{display:'block',marginTop:'100px'}}>

               <input
  type='text'
  value={destination}
  onChange={(event) => setDestination(event.target.value)}
  name='destination'
  className='form-control'
  placeholder='Renseignez la destination finale'
/>

         
      </div>

      <button style={{marginTop:'10px'}} type='submit'><i className='fa fa-qrcode'></i> Générer</button>
      </>
):data1 && data1[0].Message ==='bad' ?(
  <div className="alert alert-danger" role="alert">
     Ce code qr n'est pas valide !
                </div>
):data1 && data1[0].Message ==='existe'?(
  <div className="alert alert-warning" role="alert">
     Ce colis est déjà en sacs !
                </div>
):(
  <></>
)}
</form>
      </div> 

      
      <div align="center" id='notif' className='text-success' style={{fontWeight:'bold'}}></div>
 

        
                        
                        
                        
                        </div>
							
						</div>

                        </div>
							
						</div>











                        </div>







                        <div>
                          

                        








    
      
    
   
   
   
   </div>
   
   
   
   
 
 </div>
 
 
 
 
 
 </div>
 
 
 
 
  <nav className="mobile-bottom-nav">
     <div className="mobile-bottom-nav__item mobile-bottom-nav__item">
         <Link to='/Sacs' style={{color:'#22427C'}}>
         <div className="mobile-bottom-nav__item-content">
             <i className="fa fa-qrcode"></i>
             Gén. Qr sac
         </div>
         </Link>
     </div>
     <div className="mobile-bottom-nav__item">	
     <Link to='/Cnt'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             CNT
         </div>
         </Link>
     </div>
     <div style={{color:'#22427C'}} className="mobile-bottom-nav__item">
     <Link to='/Arrive'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             Arrivée dest.
         </div>	
         </Link>
     </div>
     
         <div style={{color:'#22427C'}} className="mobile-bottom-nav__item">
         <Link to='/Livraison'>
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-qrcode"></i>
             Livraison
         </div>	
         </Link>
     </div>
     
     <div className="mobile-bottom-nav__item">
     <Link to='/' onClick={logOut} >
         <div className="mobile-bottom-nav__item-content">
         <i className="fa fa-power-off"></i>
             Decon.
         </div>	
         </Link>
     </div>
 </nav> 





                       
</Fragment>

    )
}

